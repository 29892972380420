import React from 'react'
import Layout from '../../layouts/MainLayout'



import { graphql } from 'gatsby';
import { I18n } from 'react-i18next';
import { withI18next } from 'gatsby-plugin-i18next';
import { getContentTranslation } from '../../helpers';


const Produkty = (props) => (
    <Layout>
        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Produkty - FRT 65</h1>
                    </header>
                </div>     
            </section>
        </div>
    </Layout>
)

export default withI18next()(Produkty)


export const query = graphql`
query($lng: String!) {
  locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
    ...TranslationFragment
  }
}
`